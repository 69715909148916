
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddCompanyModal from "@/components/modals/forms/AddCompanyModal.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { array } from "yup/lib/locale";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddCompanyModal,
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const searchTearm = ref("");

    interface WIJournal {
      journal_entry_amount: string;
      journal_entry_date: string;
      journal_entry_id: string;
      journal_entry_narration: string;
      journal_entry_no: string;
      journal_type: string;
      is_approved: boolean
    }

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      getCompanyList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCompanyList(searchTearm.value);
    };

    var tableData = ref<Array<WIJournal>>([]);

    const getCompanyList = async (data) => {
      loadingData.value = true;

      try {
        var values = {
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
          // records_per_page: 1000,
        };
        console.log(values);
        await store
          .dispatch(ActionsFi.CUST_GET_JOURNAL_ENTRIES, values)
          .then((data) => {
            debugger;
            tableData.value = [];
            const totalCount = data.data.total_records;
            // set pagination
            paginationData.value["total"] = data?.data?.total_records;
            paginationData.value["start"] = data?.data?.records_from;
            paginationData.value["end"] = data?.data?.records_upto;
            paginationData.value["activePage"] = data?.data?.page;
            paginationData.value["totPage"] = data?.data?.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data?.data?.bar_range_start;
              index < data?.data?.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination
            data = data?.data;
            var resultsM = ref<Array<WIJournal>>([]);

            for (let j = 0; j < data.result_list.length; j++) {

              resultsM.value = Array({
                journal_entry_id: data.result_list[j]["journal_id"],
                journal_entry_no: data.result_list[j]["journal_no"],
                journal_entry_date: data.result_list[j]["journal_date"],
                journal_entry_amount:
                  data.result_list[j]["journal_amount"],
                journal_entry_narration:
                  data.result_list[j]["journal_remarks"],
                journal_type: data.result_list[j]["journal_typee"],
                is_approved: data.result_list[j]["is_approved"] == 0 ? false : true,
              });
              console.log("resultsm:" + resultsM.value);
              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    // onUpdated(() => {          });

    // var tableData = ref<Array<WIJournal>>([]);
    const initCustomers = ref<Array<WIJournal>>([]);

    onMounted(async () => {
      await getCompanyList(searchTearm.value);
      setCurrentPageBreadcrumbs("Journals", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const deleteFewCustomers = () => {
      checkedCompany.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCompany.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].journal_entry_id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      getCompanyList(search.value);
      console.log(search.value);
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    async function approveJournal(journal_id) {
      var user = JSON.parse(JwtService.getToken());
      let params = {
        journal_id: journal_id,
        user_id: user.user_id,
      };
      await store
        .dispatch(ActionsFi.CUST_FA_APPROVE_JOURNAL, params)
        .then(({ data }) => {
          getCompanyList(searchTearm.value);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }


    return {
      tableData,
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,
      getCompanyList,
      paginationData,
      changePageChange,
      refreshData,
      approveJournal,
      loadingData,
    };
  },
});
